import store from "@/store";

export default class Expense {
  constructor() {
    this.id = null;
    this.name = null;
    this.employee = store.state.auth.user.id;
    this.reporter = store.state.auth.user.id;
    this.travel = null;
    this.description = null;
    this.expenseManagement = null;
    this.allRequested = [];
    this.allApproved = [];
    this.expenseItems = [];
  }
}

export const PaymentType = Object.freeze({
  CASH: "CASH",
  CREDIT_CARD: "CREDIT_CARD",
  PREPAID_CARD: "PREPAID_CARD",
  OTHER: "OTHER"
});
