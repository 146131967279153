<template>
  <validation-observer ref="validator" slim>
    <v-dialog
      transition="slide-y-transition"
      class="elevation-0"
      v-model="status"
      scrollable
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title class="grey lighten-4">
          <v-list-item-content>
            <v-list-item-title class="title">{{ $t("hr.expense_approval.expense_approval") }}</v-list-item-title>
          </v-list-item-content>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="grey lighten-1" icon @click="onClose">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("buttons.close") }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mb-3 justify-center align-center" style="display: flex;">
              <v-divider />
              <span class="px-2 v-label theme--light">{{ expense.name }}</span>
              <v-divider />
            </v-col>
            <v-col cols="12">
              <v-label>
                {{ $t("expense_description") }}
              </v-label>
              <p>{{ expense.description || "-" }}</p>
            </v-col>
            <v-col cols="12" class="mb-2">
              <v-label>
                {{ $t("expense_items") }}
              </v-label>
            </v-col>
            <v-col cols="12">
              <v-card
                flat
                class="grey lighten-5 mb-1"
                :key="index"
                v-for="(expenseItem, index) in expense.expenseItems"
              >
                <v-card-text>
                  <v-row class="flex-wrap">
                    <v-col cols="12" sm="3">
                      <section style="display: flex; flex-direction: column">
                        <span>{{ expenseItem.name }}</span>
                        <span class="caption">{{ expenseItem.expenseType.name }}</span>
                        <span class="caption">{{ $t(`hr.expense.payment_type.${expenseItem.paymentType}`) }}</span>
                        <span class="caption">{{ expenseItem.dateTime | formattedDateTimeMinute }}</span>
                      </section>
                    </v-col>
                    <v-col cols="12" sm="3" class="pr-1">
                      <section style="display: flex; flex-direction: column">
                        <v-text-field
                          v-model.number="expenseItem.requested.amount"
                          readonly
                          :suffix="$helpers.getCurrencyCode(expenseItem.requested.code)"
                          color="tertiary"
                          dense
                          hide-details
                          min="0"
                          max="999999999"
                          :label="$t('currency.requested_amount')"
                        />
                      </section>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <section style="display: flex; flex-direction: column">
                        <validation-provider
                          :vid="`approvedAmount${index}`"
                          :name="$t('currency.approved_amount')"
                          rules="required|min_value:0|max_value:999999999"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model.number="expenseItem.approvedAmount"
                            type="number"
                            :suffix="$helpers.getCurrencyCode(expenseItem.approved.code)"
                            color="tertiary"
                            dense
                            min="0"
                            max="999999999"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </section>
                    </v-col>
                    <v-col cols="12" sm="3" class="text-right">
                      <section style="display: flex; justify-content: flex-end;">
                        <v-switch
                          v-model="expenseItem.abolished"
                          :true-value="false"
                          :false-value="true"
                          :label="getItemLabel(expenseItem.abolished)"
                          color="teal"
                          style="flex: 0;"
                        />
                      </section>
                    </v-col>
                    <v-col cols="12" v-if="expenseItem.abolished">
                      <validation-provider
                        :vid="`abolishReason${index}`"
                        :name="$t('global.reason')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="expenseItem.abolishReason"
                          color="tertiary"
                          dense
                          :label="$t('global.reason')"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model.trim="expenseRequestProcessDTO.note" name="note" :label="$t('global.message')" />
            </v-col>
            <v-col cols="12">
              <v-radio-group row mandatory v-model="expenseRequestProcessDTO.approved">
                <v-radio
                  :disabled="radioBtnDisabled"
                  color="primary"
                  :value="false"
                  :label="$t('leave.request.denied')"
                />
                <v-radio
                  :disabled="radioBtnDisabled"
                  color="primary"
                  :value="true"
                  :label="$t('leave.request.approved')"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer />
          <v-btn :loading="loading" color="primary" depressed @click="onClickSendApprovedRequest">
            {{ $t("buttons.send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
  import Expense from "@/pages/hr/expense/expense/model/Expense.js";

  export default {
    name: "RequestApprover",
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object
      }
    },
    data: () => ({
      status: false,
      loading: false,
      currencyCode: null,
      requestedAmount: null,
      description: null,
      expense: new Expense(),
      expenseRequestProcessDTO: {
        approved: false,
        note: null,
        expenseItems: []
      },
      radioBtnDisabled: false
    }),
    watch: {
      value: {
        handler(v) {
          this.status = v;
          if (v && this.$refs.validator) {
            this.$refs.validator.reset();
          }
        },
        immediate: true
      },
      data: {
        handler(val) {
          if (val) {
            val.expenseItems = val.expenseItems
              .map(expenseItem => ({
                ...expenseItem,
                approvedAmount: expenseItem.approved.amount,
                abolished: expenseItem.abolished || false
              }))
              .filter(expenseItem => !expenseItem.abolished);
            this.expense = this.$helpers.cloneDeep(val);
          }
        },
        immediate: true
      },
      "expense.expenseItems": {
        deep: true,
        handler(val) {
          if (val.length === 1) {
            this.radioBtnDisabled = true;
            this.expenseRequestProcessDTO.approved = !val[0].abolished;
          } else {
            this.radioBtnDisabled = false;
          }
        }
      }
    },
    methods: {
      getItemLabel(abolished) {
        return abolished ? this.$t("leave.request.denied") : this.$t("leave.request.approved");
      },
      onClickSendApprovedRequest() {
        this.$refs.validator.validate().then(valid => {
          if (valid) {
            this.expenseRequestProcessDTO.expenseItems = this.expense.expenseItems.map(expenseItem => ({
              ...expenseItem,
              approved: expenseItem.approvedAmount
            }));
            this.loading = true;
            this.$api.expenseService
              .approve(this.expense.process, this.expenseRequestProcessDTO)
              .then(response => {
                if (!response.data.error) {
                  this.$emit("success");
                  this.resetState();
                }
              })
              .catch(console.error)
              .then(() => (this.loading = false));
          }
        });
      },
      resetState() {
        this.expenseRequestProcessDTO.note = null;
        this.expenseRequestProcessDTO.approved = false;
        this.expenseRequestProcessDTO.expenseItems = [];
      },
      onClose() {
        this.resetState();
        this.$emit("close");
      }
    }
  };
</script>
